/* General Styles */

/* Contact Form Container */
.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.contact-form {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  text-align: center;
}

.form-title {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #8ad831;
}

.form-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

/* Input Field Styles */
.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-field:focus {
  border-color: main;
  box-shadow: 0 0 5px #8ad831;
}

textarea.input-field {
  resize: vertical;
  min-height: 100px;
}

/* Submit Button Styles */
.submit-btn {
  background-color: #8ad831;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 8px;
  width: 100%;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #8ad831;
}

.submit-btn:active {
  background-color: #8ad831;
}

/* Status Message */
.status-message {
  margin-top: 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}
.status-message.success {
  color: #8ad831;
}
.status-message.error {
  color: red;
}
