/* CSS for VisitorEmployees and EmployeeCard */
.visitor-employees {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.visitor-employees-title {
  text-align: center;
  margin-bottom: 20px;
}

.visitor-employees-title h1 {
  font-size: 24px;
  color: #333;
}

.visitor-employees-title p {
  font-size: 16px;
  color: #666;
  margin-top: 5px;
}

.employee-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;
}

.employee-card {
  flex: 0 0 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.employee-card:hover {
  transform: translateY(-5px);
}

.employee-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.employee-details {
  flex-grow: 1;
}

.employee-details h2 {
  font-size: 18px;
  margin-top: 0;
  color: #333;
}

.employee-details p {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.employee-details ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.employee-details li {
  padding: 5px 0;
}

.employee-details a {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  background-color: #d2ebcd;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.employee-details a:hover {
  background-color: #d2eeef;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #d2ebcd;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #d2eeef;
}

.pagination button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .employee-card {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .employee-image {
    width: 80px;
    height: 80px;
  }

  .employee-details h2 {
    font-size: 16px;
  }

  .employee-details p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .employee-card {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .employee-image {
    width: 60px;
    height: 60px;
  }

  .employee-details h2 {
    font-size: 14px;
  }

  .employee-details p {
    font-size: 12px;
  }

  .pagination button {
    padding: 8px 12px;
    font-size: 12px;
  }
}
