/* CSS for Visitorproducts and productCard */
.products {
  max-width: 1600px;
  margin: 40px auto;
  padding: 20px;
  background-color: rgba(245, 245, 245, 0.5);
  opacity: 1;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.products-title {
  text-align: center;
  margin-bottom: 20px;
}

.products-title h1 {
  font-size: 24px;
  color: #333;
}

.products-title p {
  font-size: 16px;
  color: #666;
  margin-top: 5px;
}

.product-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;
}

.product-card {
  flex: 0 0 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  padding: 20px;
  background-color: rgba(245, 245, 245, 0.5);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-image-backup {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.product-details {
  flex-grow: 1;
}

.product-details h2 {
  font-size: 18px;
  margin-top: 0;
  color: #333;
}

.product-details p {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.product-details ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.product-details li {
  padding: 5px 0;
}

.product-details a {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.product-details a:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .product-card {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .product-image {
    width: 100%;
    height: 80px;
  }

  .product-details h2 {
    font-size: 16px;
  }

  .product-details p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .product-card {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .product-image {
    width: 100%;
    height: 120px;
  }

  .product-details h2 {
    font-size: 14px;
  }

  .product-details p {
    font-size: 12px;
  }

  .pagination button {
    padding: 8px 12px;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .product-card {
    flex-direction: row;
  }

  .product-image {
    width: 50%;
    margin-right: 10px;
  }

  .product-details {
    flex-grow: 1;
    flex-direction: column;
  }
}
