.contactus {
  max-width: 1600px;
  margin: 40px auto;
  padding: 20px;
  background-color: rgba(245, 245, 245, 0.5);
  opacity: 1;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.equal-height {
  display: flex;
}

.contact-card {
  min-height: 200px;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-card h3 {
  font-size: 24px;
  color: #007bff;
}

.phone-number {
  font-size: 18px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone-icon,
.email-icon {
  font-size: 24px;
  margin-right: 8px;
}

#contactConsent {
  margin-right: 5px;
}

label[for="contactConsent"] {
  font-size: 14px;
  color: #555;
}
